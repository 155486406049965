<template>
  <div>
    <Loader absolute :loading="loading" />
    <div class="d-flex justify-space-between">
      <div style="width: 150px;">
        <v-select
          v-model="perPage"
          label="Total visible"
          :items="listPerPage"
          @change="resetCurrentPage"
          dense
        />
      </div>
      <div>
        <v-btn
          @click="getOrders"
          color="warning"
          small
          text
        >
          <v-icon left>mdi-refresh</v-icon>
          Refrescar
        </v-btn>
        <v-btn
          @click="createOrder"
          color="primary"
          small
          text
        >
          <v-icon left>mdi-plus</v-icon>
          Orden
        </v-btn>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="orders"
      class="elevation-0"
      :item-class="rowClassByStatus"
      disable-pagination
      hide-default-footer
      fixed-header
    >
      <template v-slot:item.actions="{ item }">
        <v-progress-circular
          v-if="currentOrdersId.includes(item.id)"
          indeterminate
          color="primary"
          size="20"
          width="3"
        />
        <v-menu v-else offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list
            dense
          >
            <v-list-item
              v-if="item.type === 'SALE_PAYMENT'"
              link
              @click="openCredits(item)"
            >
              <v-icon left>mdi-currency-usd</v-icon>
              <small>Pagos</small>
            </v-list-item>
            <v-list-item
              link
              @click="getReport(item.id)"
            >
              <v-list-item-title>
                <v-icon left>mdi-printer</v-icon>
                Imprimir
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="item.status.name !== 'CANCEL' && item.payments.length === 0"
              link
              @click="preCancelOrder(item)"
            >
              <v-icon left>mdi-cancel</v-icon>
              <small>Anular</small>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.origin="{ item }">
        <div v-if="item.origin_warehouse_id !== null">
          <div>
            <small class="text--primary">
              <v-icon left x-small> {{ icons[item.origin.type] }} </v-icon>
              {{ item.origin.name }}
            </small>
          </div>
          <div>
            <small class="text--primary"> {{ item.origin.description }} </small>
          </div>
        </div>
      </template>
      <template v-slot:item.destiny="{ item }">
        <Destiny
          :detail="item.buy_contact"
          type-detail="contact"
        />
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          v-if="item.status.name === 'UNDERPAYMENT' && item.payments.length > 0"
          color="orange"
          small
          label
          outlined
        >
          AMORTIZADO
        </v-chip>
        <v-chip
          v-else
          :color="colors[item.status.name]"
          small
          label
          outlined
        >
          {{ translators[item.status.name] }}
        </v-chip>
      </template>
      <template v-slot:item.type_order="{ item }">
        <div><b> {{ orderType[item.type] }} </b></div>
      </template>
      <template v-slot:item.order_num="{ item }">
        <b> {{ item.order_num }} </b>
      </template>
      <template v-slot:item.items="{ item }">
        <TTruncate
          v-for="sku in item.items"
          :key="`sku-item-${sku.id}`"
          :text="`${sku.sku.sku} x ${sku.quantity}(${sku.package_name})`"
          :title="`${sku.sku.sku} - ${sku.sku.product.name}`"
          :width="150"
          small
        />
      </template>
      <template v-slot:item.total="{ item }">
        <b class="success--text"> Bs. {{ item.total }} </b>
        <small v-if="item.status.name === 'UNDERPAYMENT'">
          <div class="d-flex justify-start">
            <b>Pagado:</b> &nbsp;{{ totalCredits(item) }}
          </div>
          <div class="d-flex justify-start">
            <b>Pendiente</b> &nbsp;{{
              (item.total - totalCredits(item)).toFixed(2)
            }}
          </div>
        </small>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination
        v-model="currentPage"
        :disabled="loading"
        :length="lastPage"
        :total-visible="8"
      />
    </div>
    <Creditst
      ref="orderCredits"
      @onUpdateOrder="onUpdateOrder"
    />
    <MyDialog
      ref="myDialog"
      @onYes="onCancelOrder"
    />
    <FramePdf
      :fileUrl="fileUrl"
    />
  </div>
</template>

<script>
// services
import OrderServices from '@/store/services/warehouse/OrderServices';
// components
import TTruncate from '@/components/commons/tiny/TTruncate.vue';
import Destiny from './OrderDO';
import Creditst from './order-sale/OrderCredits';
// helpers
import { mapGetters } from 'vuex';
import ListMixin from '@/mixins/commons/ListMixin';
import { WAREHOUSE_ICON, STATUS_COLOR, STATUS_TRANSLATOR, ORDER_TYPE } from '@/constants';
import FramePdf from '../../general/reports/FramePdf';

export default {
  mixins: [ListMixin],
  components: {
    TTruncate,
    Destiny,
    Creditst,
    FramePdf
  },
  data() {
    return {
      loading: false,
      orders: [],

      icons: WAREHOUSE_ICON,
      colors: STATUS_COLOR,
      translators: STATUS_TRANSLATOR,
      orderType: ORDER_TYPE,

      currentOrdersId: [],
      fileUrl: null,
    };
  },
  created() {
    this.getOrders();
  },
  methods: {
    async getReport(order) {
      try {
        this.loading = true
        let response = await OrderServices.file(this.user.archon.id, order, {
          params: this.data,
          responseType: 'blob',
          Accept: 'application/pdf',
        })
        const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
        this.fileUrl = url
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    totalCredits(item) {
      let total = 0;
      item.payments.forEach((payment) => {
        total += parseFloat(payment.pay_fee);
      });
      return total.toFixed(2);
    },
    rowClassByStatus(item) {
      let totalCredits = 0;
      item.payments.forEach(item => {
        totalCredits += item.pay_fee;
      });
      if (item.total > totalCredits && item.status.name === 'UNDERPAYMENT') {
        return ['red lighten-4', 'disabled']; //can also return multiple classes e.g ["orange","disabled"]
      } 
    },
    onUpdateOrder(data) {
      const index = this.orders.findIndex(order => order.id === data.order_id);
      this.orders[index].status_id = data.status.id;
      this.orders[index].status = data.status;
    },
    openCredits(item) {
      this.$refs['orderCredits'].dialog = true;
      this.$refs['orderCredits'].loadCredits(item);
    },
    preCancelOrder(item) {
      const myDialog = this.$refs['myDialog'];
      myDialog.data = item;
      myDialog.configuration = {
        iconTitle: 'mdi-cancel',
        title: 'Anular',
        message: `¿Esta seguro de Anular la Order ${item.order_num}?`,
        typeDialog: 'warning',
      };
      myDialog.dialog = true;
    },
    async onCancelOrder(data) {
      try {
        this.currentOrdersId.push(data.id);
        await OrderServices.cancelOrder(this.user.archon.id, data.id);
        const index = this.orders.findIndex(item => item.id === data.id);
        this.orders[index].status_id = 4;
        this.orders[index].status = {
          id: 4,
          name: 'CANCEL',
        };
        this.currentOrdersId = [];
      } catch (error) {
        console.log(error);
        this.currentOrdersId = [];
      }
    },
    async getOrders() {
      try {
        const params = {
          search_type: ['SALE', 'SALE_PAYMENT'],
          page: this.currentPage,
          order_by: 'id',
          order_direction: 'DESC',
          per_page: typeof this.perPage === 'string' ? this.total : this.perPage,
          include: 'items.sku.product,origin,status,buyContact.contactable,billContact.contactable,payments.user',
        };
        this.loading = true;
        const response = await OrderServices.index(this.user.archon.id, params);
        this.orders = response.data.data;
        this.currentPage = response.data.current_page;
        this.lastPage = response.data.last_page;
        this.total = response.data.total;
        
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    createOrder() {
      this.$router.push({
        name: 'orderSaleCreate',
      });
    },
    // list
    resetCurrentPage() {
      if (this.currentPage === 1) this.getOrders();
      else this.currentPage = 1;
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    headers() {
      return [
        {
          text: '',
          sortable: false,
          value: 'actions',
        },
        {
          text: 'Orden #',
          sortable: false,
          value: 'order_num',
        },
        // { text: 'Instrucciones', value: 'instructions', sortable: false, align: 'center' },
        { text: 'Origen', value: 'origin', sortable: false },
        { text: 'Destino', value: 'destiny', sortable: false },
        { text: 'Tipo Orden', value: 'type_order', sortable: false },
        { text: 'Estado', value: 'status', sortable: false },
        { text: 'Items', value: 'items', sortable: false },
        { text: 'Total', value: 'total', sortable: false },
      ];
    },
  },
  watch: {
    currentPage() {
      this.getOrders();
    },
  },
}
</script>