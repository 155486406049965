import ApiService from '../Api';

class OrderPaymentServices extends ApiService {
  constructor() {
    super('/archons');
  }

  index(archon, params = {}) {
    return this.api.get(`${this.resource}/${archon}/order-payments`, { params });
  }

  store(archon, body) {
    return this.api.post(`${this.resource}/${archon}/order-payments`, body);
  }

  destroy(archon, orderPayment) {
    return this.api.delete(`${this.resource}/${archon}/order-payments/${orderPayment}`);
  }
}

export default new OrderPaymentServices();