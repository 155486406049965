export const isEmail = (value) => {
  // eslint-disable-next-line
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
};

export const isGreaterThan = (valueA, valueB) => {
  return valueB > valueA
};

export const isInteger = (value) => {
  return parseFloat(value) === parseInt(value);
};

export const isNit = (value) => {
  return /^\d+$/.test(value);
};

export const isObject = (obj) => {
  let keys = 0;
  // eslint-disable-next-line
  for (const key in obj) {
    keys++;
  }
  return keys > 0;
};

export const isDecimal2 = (value) => {
    return /^\s*-?[0-9]\d*(\.\d{1,2})?\s*$/.test(value);
};