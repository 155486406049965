<template>
  <v-dialog v-model="dialog" persistent width="60%">
    <v-card :loading="loading" :disabled="loading">
      <v-card-title>
        <v-icon left>mdi-currency-usd</v-icon>
        Pagos de Crédito
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="5" sm="11">
            <v-card outlined>
              <v-card flat>
                <v-card-text>
                  <v-form ref="form" onSubmit="return false;">
                    <v-text-field
                      id="inputAmount"
                      v-model="amount"
                      label="Monto"
                      dense
                      outlined
                      :rules="[isRequired, isDecimal2, validateCredit]"
                      type="number"
                      step="0.01"
                      @click="textSelect"
                    />
                    <div class="d-flex justify-end">
                      <v-btn
                        @click="storeCredit"
                        small
                        color="primary"
                        :disabled="loadingStore || order.status.name !== 'UNDERPAYMENT'"
                        :loading="loadingStore"
                      >
                        Realizar Pago
                      </v-btn>
                    </div>
                  </v-form>
                </v-card-text>
              </v-card>
              <v-card class="py-1 px-4" flat>
                <small>
                  <div class="d-flex justify-end">
                    <b>Total Orden:</b> &nbsp; {{ order.total }}
                  </div>
                  <div class="d-flex justify-end">
                    <b>Pagado:</b> &nbsp;{{ totalPayments }}
                  </div>
                  <div class="d-flex justify-end">
                    <b>Pendiente</b> &nbsp;{{
                      (order.total - totalPayments).toFixed(2)
                    }}
                  </div>
                </small>
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="12" md="7" sm="12">
            <TScroll
              :height="240"
              :unaffected-rows="3"
              :rows="payments.length"
            >
              <v-list two-line>
                <v-list-item-group active-class="success--text">
                  <v-divider />
                  <template v-for="(item, index) in payments">
                    <v-list-item :key="item.id" link>
                      <v-list-item-content>
                        <v-list-item-title>
                          <small>Monto: Bs {{ item.pay_fee }}</small>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <small>Usuario: {{ item.user.name }}</small>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <small class="text--secondary">Fecha: {{ getDateFormat(item.created_at) }}</small>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div
                          v-if="selectedPaymentId === item.id"
                        >
                          <small>¿Seguro de eliminar?</small>
                          <v-btn x-small text @click="removeCredit(item)" color="red">YES</v-btn>
                          <v-btn x-small text @click="selectedPaymentId = -1" color="grey lighten-1">NO</v-btn>
                        </div>
                        <v-btn
                          v-else
                          color="red"
                          :loading="orderCreditId === item.id"
                          :disabled="orderCreditId === item.id || order.status.name !== 'UNDERPAYMENT'"
                          @click="beforeRemoveCredit(item)"
                          small
                          icon
                        >
                          <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider :key="`div-price-${index}`" />
                  </template>
                </v-list-item-group>
              </v-list>
            </TScroll>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="dialog = false" small color="grey lighten-1">
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TScroll from "@/components/commons/tiny/TScroll";
import StatusServices from '@/store/services/general/StatusServices';
import OrderPaymentServices from "@/store/services/warehouse/OrderPaymentServices";
import OrderServices from "@/store/services/warehouse/OrderServices";
import { isDecimal2 } from "@/constants/rules";
import { getDateFormat } from "@/constants/helpers";
import { mapGetters } from "vuex";
export default {
  components: {
    TScroll,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      loadingStore: false,
      orderCreditId: -1,

      payments: [],

      amount: 0,

      order: {
        id: -1,
        total: 0,
        status: {
          name: '',
        },
      },

      statuses: [],

      // rules
      isDecimal2: (value) =>
        isDecimal2(value) || "Campo debe tener maximo dos decimales",
      isRequired: (value) => !!value || "Campo requerido",
      validateCredit: (value) =>
        this.validateCredits(value) ||
        "El pago de los créditos no puede ser mayor que el total de la orden",

      selectedPaymentId: -1,
    };
  },
  created() {
    this.getStatuses();
  },
  methods: {
    async getStatuses() {
      try {
        const response = await StatusServices.index(this.user.archon.id);
        this.statuses = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    getStatus(name) {
      const index = this.statuses.findIndex(status => status.name === name);
      return this.statuses[index];
    },
    textSelect() {
      const input = document.getElementById('inputAmount');
      input.focus();
      input.select();
    },
    getDateFormat(date) {
      return getDateFormat(date, true);
    },
    validateCredits(value) {
      return parseFloat(this.order.total) >= (parseFloat(this.totalPayments) + parseFloat(value));
    },
    async storeCredit() {
      if (this.$refs.form.validate()) {
        try {
          this.loadingStore = true;
          const response = await OrderPaymentServices.store(this.user.archon.id, {
            order_id: this.order.id,
            user_id: this.user.id,
            pay_fee: this.amount,
          });
          this.payments.unshift(response.data);
          if (parseFloat(this.order.total).toFixed(2) === parseFloat(this.totalPayments + this.amount).toFixed(2)) {
            await this.updateOrder();
          }
          this.resetData();
          this.loadingStore = false;
        } catch (error) {
          this.loadingStore = false;
          console.log(error);
        }
      }
    },
    async updateOrder() {
      try {
        this.loadingStore = true;
        const status = this.getStatus('SALE');
        const newOrder = Object.assign({}, this.order);
        newOrder.status_id = status.id;
        newOrder.prev_status = 'UNDERPAYMENT';
        delete newOrder.id;
        delete newOrder.status;
        delete newOrder.items;
        await OrderServices.update(this.user.archon.id, this.order.id, newOrder);
        this.$emit('onUpdateOrder', {
          order_id: this.order.id,
          status, 
        });
        this.loadingStore = false;
      } catch (error) {
        this.loadingStore = false;
        console.log(error);
      }
    },
    beforeRemoveCredit(item) {
      this.selectedPaymentId = item.id;
    },
    async removeCredit(item) {
      try {
        this.orderCreditId = item.id;
        await OrderPaymentServices.destroy(this.user.archon.id, item.id);
        const index = this.payments.findIndex(credit => credit.id === item.id);
        this.payments.splice(index, 1);
        this.orderCreditId = -1;
        this.selectedPaymentId = -1;
      } catch (error) {
        this.orderCreditId = -1;
        console.log(error);
      }
    },
    resetData() {
      this.amount = 0;
      this.$refs.form.resetValidation();
    },
    async getCredits(orderId) {
      try {
        this.payments = [];
        this.loading = true;
        const params = {
          include: 'user',
          search_order: orderId,
        };
        const response = await OrderPaymentServices.index(
          this.user.archon.id,
          params
        );
        this.payments = response.data.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    loadCredits(order) {
      this.order = Object.assign({}, order);
      this.payments = order.payments;
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    totalPayments() {
      let total = 0;
      this.payments.forEach((credit) => {
        total += parseFloat(credit.pay_fee);
      });
      return total.toFixed(2);
    },
  },
};
</script>